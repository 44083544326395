import styles from '../styles/Home.module.css';
import React from 'react';
import ProjectWidget from './aux/projectWidget.js';

export default function Projects() {
  return (
    <main className={styles.main}>
        <ProjectWidget />
        <div className={styles.but} onClick={() => document.getElementById("contact").scrollIntoView({ behavior: 'smooth' })}>
          <h2 className={styles.borderFade}>
            Get in touch with me
          </h2>
        </div>
    </main>
  )
}
