import styles from './styles/Home.module.css'
import AboutMe from './components/aboutme';
import Contact from './components/contact';
import Cv from './components/cv';
import Footer from './components/footer';
import Landing from './components/landing';
import Navbar from './components/navbar';
import Projects from './components/projects';
import React from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const useMediaQuery = (width) => {
  const [targetReached, setTargetReached] = React.useState(false);

  const updateTarget = React.useCallback((e) => {
    if (e.matches) {
      setTargetReached(true);
    } else {
      setTargetReached(false);
    }
  }, []);

  React.useEffect(() => {
    const media = window.matchMedia(`(max-width: ${width}px)`)
    media.addEventListener('change', e => updateTarget(e))

    // Check on mount (callback is not called until a change occurs)
    if (media.matches) {
      setTargetReached(true)
    }

    return () => media.removeEventListener('change', e => updateTarget(e))
  }, []);

  return targetReached;
};

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
  },
  typography: {
    button: {
      textTransform: 'none',
    }
  }
});

export default function Home() {
  smoothscroll.polyfill();
  const isBreakpoint = useMediaQuery(1023);

  return (
    <ThemeProvider theme={darkTheme}>
      <div className={styles.tot}>
        <div className={styles.bg} style={{backgroundImage: 'url(/img/bg.jpg)'}}>
          <div className={styles.container} id={"home"}>
            <Landing />
          </div>
        </div>
        <div className={styles.bg} style={{backgroundImage: 'url(/img/aboutbg.jpg)'}}>
          <div className={styles.container} id={"aboutme"}>
            <AboutMe />
          </div>
        </div>
        <div className={styles.bg} style={{backgroundImage: 'url(/img/projbg.jpg)'}}>
          <div className={styles.container} id={"projects"}>
            <Projects />
          </div>
        </div>
        <div className={styles.bg} style={{backgroundImage: 'url(/img/contactbg.jpg)'}}>
          <div className={styles.container} id={"contact"}>
            <Contact />
          </div>
        </div>
        <div className={styles.bg} style={{backgroundImage: 'url(/img/cvbg.jpg)'}}>
          <div className={styles.container} id={"cv"}>
            <Cv />
          </div>
        </div>
        {!isBreakpoint && <Footer />}
        {isBreakpoint && <Navbar />}
      </div>
    </ThemeProvider>
  )
}
