import styles from '../../styles/Home.module.css';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useMediaQuery = (width) => {
  const [targetReached, setTargetReached] = React.useState(false);

  const updateTarget = React.useCallback((e) => {
    if (e.matches) {
      setTargetReached(true);
    } else {
      setTargetReached(false);
    }
  }, []);

  React.useEffect(() => {
    const media = window.matchMedia(`(max-width: ${width}px)`)
    media.addEventListener('change', e => updateTarget(e))

    // Check on mount (callback is not called until a change occurs)
    if (media.matches) {
      setTargetReached(true)
    }

    return () => media.removeEventListener('change', e => updateTarget(e))
  }, []);

  return targetReached;
};

const useStyles = makeStyles({
  root: {
    width: 345,
    marginTop: '1vh',
    marginBottom: '1vh',
    marginLeft: '1vw',
    marginRight: '1vw',
    boxShadow: '2px 2px 5px #1f1f1f',
  },
});

export default function ProjectWidget() {
  const classes = useStyles();
  const isBreakpoint = useMediaQuery(1023);

  return (
    <>
        {isBreakpoint &&
          <>
            <h1 className={styles.title}>
                My Recent Project
            </h1>
            <Card className={classes.root}>
              <a href={"https://lights-on.icedcoffee.dev/"} target="_blank" rel="noreferrer">
                <CardMedia
                  className={styles.cardArea}
                  image="/img/projects/lo.png"
                  title="Lights On"
                />
              </a>
                <CardContent style={{padding: '10px'}}>
                  <div className={styles.tags}>
                    <span className={styles.tag}>Unity3D</span>
                    <span className={styles.tag}>Team Project</span>
                    <span className={styles.tag}>University</span>
                    <span className={styles.tag}>Flagship Technologies</span>
                    <span className={styles.tag}>Game</span>
                    <a href={"https://lights-on.icedcoffee.dev/"} target="_blank" rel="noreferrer" className={styles.anchorPad}>
                      <span className={styles.descTag}>Find out more</span>
                    </a>
                  </div>
                </CardContent>
            </Card>
            <p className={styles.description}>Visit this website on a computer to see more projects and links.</p>
          </>
        }
        {!isBreakpoint &&
          <>
            <h1 className={styles.title}>
                My Recent Projects
            </h1>
            <div className={styles.column}>
              <div className={styles.rowProj}>
                <Card className={classes.root}>
                  <a href={"https://lights-on.icedcoffee.dev"} target="_blank" rel="noreferrer">
                    <CardMedia
                      className={styles.cardArea}
                      image="/img/projects/lo.png"
                      title="Lights On"
                    />
                  </a>
                  <CardContent style={{padding: '10px'}}>
                    <div className={styles.tags}>
                      <span className={styles.tag}>Unity3D</span>
                      <span className={styles.tag}>Team Project</span>
                      <span className={styles.tag}>University</span>
                      <span className={styles.tag}>Flagship Technologies</span>
                      <span className={styles.tag}>Game</span>
                      <a href={"https://lights-on.icedcoffee.dev"} target="_blank" rel="noreferrer" className={styles.anchorPad}>
                        <span className={styles.descTag}>Find out more</span>
                      </a>
                    </div>
                  </CardContent>
                </Card>
                <Card className={classes.root}>
                  <a href={"https://vibes.danjones.dev/"} target="_blank" rel="noreferrer">
                    <CardMedia
                      className={styles.cardArea}
                      image="/img/projects/va.png"
                      title="Vibes Ahead"
                    />
                  </a>
                    <CardContent style={{padding: '10px'}}>
                      <div className={styles.tags}>
                        <span className={styles.tag}>p5.js</span>
                        <span className={styles.tag}>Blender</span>
                        <span className={styles.tag}>Game Jam</span>
                        <a href={"https://vibes.danjones.dev/"} target="_blank" rel="noreferrer" className={styles.anchorPad}>
                          <span className={styles.descTag}>Find out more</span>
                        </a>
                      </div>
                    </CardContent>
                </Card>
              </div>
              <div className={styles.rowProj}>
                <Card className={classes.root}>
                  <a href={"https://njord.icedcoffee.dev/"} target="_blank" rel="noreferrer">
                    <CardMedia
                      className={styles.cardArea}
                      image="/img/projects/njord.png"
                      title="Njord"
                    />
                  </a>
                  <CardContent style={{padding: '10px'}}>
                    <div className={styles.tags}>
                      <span className={styles.tag}>Unity3D</span>
                      <span className={styles.tag}>React</span>
                      <span className={styles.tag}>Hackathon</span>
                      <a href={"https://njord.icedcoffee.dev/"} target="_blank" rel="noreferrer" className={styles.anchorPad}>
                        <span className={styles.descTag}>Find out more</span>
                      </a>
                    </div>
                  </CardContent>
                </Card>
                <Card className={classes.root}>
                <a href={"https://github.com/bogdumi/woodshack"} target="_blank" rel="noreferrer">
                  <CardMedia
                    className={styles.cardArea}
                    image="/img/projects/ws.png"
                    title="Woodshack"
                  />
                </a>
                <CardContent style={{padding: '10px'}}>
                  <div className={styles.tags}>
                    <span className={styles.tag}>React</span>
                    <span className={styles.tag}>Boeing</span>
                    <span className={styles.tag}>Hackathon</span>
                    <a href={"https://github.com/bogdumi/woodshack"} target="_blank" rel="noreferrer" className={styles.anchorPad}>
                      <span className={styles.descTag}>Find out more</span>
                    </a>
                  </div>
                </CardContent>
                </Card>
              </div>
            </div>
          </>
        }
    </>
  )
}
