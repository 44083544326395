import Avatar from '@material-ui/core/Avatar';
import React from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import styles from '../styles/Home.module.css';
import { makeStyles } from '@material-ui/core/styles';

const useMediaQuery = (width) => {
  const [targetReached, setTargetReached] = React.useState(false);

  const updateTarget = React.useCallback((e) => {
    if (e.matches) {
      setTargetReached(true);
    } else {
      setTargetReached(false);
    }
  }, []);

  React.useEffect(() => {
    const media = window.matchMedia(`(max-width: ${width}px)`)
    media.addEventListener('change', e => updateTarget(e))

    // Check on mount (callback is not called until a change occurs)
    if (media.matches) {
      setTargetReached(true)
    }

    return () => media.removeEventListener('change', e => updateTarget(e))
  }, []);

  return targetReached;
};

export default function AboutMe() {
  React.useEffect(() => {
    // window is accessible here.
    smoothscroll.polyfill();
  }, []);

  const isBreakpoint = useMediaQuery(1023);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: theme.spacing(13),
      height: theme.spacing(13),
    },
    large: {
      width: theme.spacing(21),
      height: theme.spacing(21),
    },
  }));
  
  const classes = useStyles();

  return (
    <main className={styles.main}>
      {!isBreakpoint && <Avatar alt="Bogdan Dumitrescu" src="/img/bd.jpg" className={classes.large}/>}
      {isBreakpoint&& <Avatar alt="Bogdan Dumitrescu" src="/img/bd.jpg" className={classes.small}/>}

        <h1 className={styles.title}>
            About Me
        </h1>
        {!isBreakpoint &&
          <p className={styles.description}>
              I am currently a Technology Analyst at Morgan Stanley. I am always looking to develop my problem-solving and programming skills, and to contribute to solving digital challenges in technology. I also enjoy film and digital photography, and I like tinkering with various objects.
          </p>
        }
        {isBreakpoint &&
          <p className={styles.description}>
              I am currently a Technology Analyst at Morgan Stanley. I am always looking to develop my problem-solving and programming skills, and to contribute to solving digital challenges in technology. I also enjoy film and digital photography, and I like tinkering with various objects.
          </p>
        }
        {!isBreakpoint &&
          <div className={styles.but} onClick={() => document.getElementById("projects").scrollIntoView({ behavior: 'smooth' })}>
            <h2 className={styles.borderFade}>
              Here are some of my projects
            </h2>
          </div>
        }
        {isBreakpoint &&
          <div className={styles.but} onClick={() => document.getElementById("projects").scrollIntoView({ behavior: 'smooth' })}>
            <h2 className={styles.borderFadeSmall}>
              My recent projects
            </h2>
          </div>
        }
    </main>
  )
}
