import styles from '../../styles/Home.module.css';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useMediaQuery = (width) => {
  const [targetReached, setTargetReached] = React.useState(false);

  const updateTarget = React.useCallback((e) => {
    if (e.matches) {
      setTargetReached(true);
    } else {
      setTargetReached(false);
    }
  }, []);

  React.useEffect(() => {
    const media = window.matchMedia(`(max-width: ${width}px)`)
    media.addEventListener('change', e => updateTarget(e))

    // Check on mount (callback is not called until a change occurs)
    if (media.matches) {
      setTargetReached(true)
    }

    return () => media.removeEventListener('change', e => updateTarget(e))
  }, []);

  return targetReached;
};

const useStyles = makeStyles({
    root: {
      width: 345,
      marginTop: '1vh',
      marginBottom: '1vh',
      marginLeft: '1vw',
      marginRight: '1vw',
      boxShadow: '2px 2px 5px #1f1f1f',
    },
    rootMobile: {
      width: 270,
      maxHeight: 170,
      marginTop: '0.75vh',
      marginBottom: '0.75vh',
      marginLeft: '1vw',
      marginRight: '1vw',
      boxShadow: '2px 2px 5px #1f1f1f',
    },
  });

export default function CvWidget() {
    const classes = useStyles();
    const isBreakpoint = useMediaQuery(1023);

    return (
        <>
            {!isBreakpoint &&
            <>
                <p className={styles.description}>
                    You can take a look at my formal CV here. Want to print it out? You can find a printer-friendly black & white version below as well.
                </p>
                <div className={styles.row}>
                    <Card className={classes.root}>
                        <a href={"/docs/BogdanDumitrescuCV.pdf"} target="_blank" rel="noreferrer">
                            <CardMedia
                                className={styles.cardArea}
                                image="/img/cv/cv.png"
                                title="CV"
                            />
                        </a>
                        <CardContent style={{padding: '10px'}}>
                            <div className={styles.tags}>
                                <span className={styles.tag}>PDF</span>
                                <span className={styles.tag}>Colour</span>
                                <a href={"/docs/BogdanDumitrescuCV.pdf"} className={styles.anchorPad} target="_blank" rel="noreferrer">
                                    <span className={styles.descTag}>Web Version</span>
                                </a>
                            </div>
                        </CardContent>
                    </Card>
                    <Card className={classes.root}>
                        <a href={"/docs/BogdanDumitrescuCVBW.pdf"} target="_blank" rel="noreferrer">
                            <CardMedia
                                className={styles.cardArea}
                                image="/img/cv/cvbw.png"
                                title="CVBW"
                            />
                        </a>
                        <CardContent style={{padding: '10px'}}>
                            <div className={styles.tags}>
                                <span className={styles.tag}>PDF</span>
                                <span className={styles.tag}>Black & White</span>
                                <a href={"/docs/BogdanDumitrescuCVBW.pdf"} className={styles.anchorPad} target="_blank" rel="noreferrer" >
                                    <span className={styles.descTag}>Printer-friendly</span>
                                </a>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </>
            }
            {isBreakpoint && 
                <div className={styles.column}>
                    <Card className={classes.rootMobile}>
                        <a href={"/docs/BogdanDumitrescuCV.pdf"}>
                            <CardMedia
                                className={styles.cardAreaMobile}
                                image="/img/cv/cv.png"
                                title="CV"
                            />
                        </a>
                        <CardContent style={{padding: '10px'}}>
                            <div className={styles.tags}>
                                <span className={styles.tag}>PDF</span>
                                <span className={styles.tag}>Colour</span>
                                <a href={"/docs/BogdanDumitrescuCV.pdf"} className={styles.anchorPad}>
                                    <span className={styles.descTag}>Web Version</span>
                                </a>
                            </div>
                        </CardContent>
                    </Card>
                    <Card className={classes.rootMobile}>
                        <a href={"/docs/BogdanDumitrescuCVBW.pdf"}>
                            <CardMedia
                                className={styles.cardAreaMobile}
                                image="/img/cv/cvbw.png"
                                title="CVBW"
                            />
                        </a>
                        <CardContent style={{padding: '10px'}}>
                            <div className={styles.tags}>
                                <span className={styles.tag}>PDF</span>
                                <span className={styles.tag}>Black & White</span>
                                <a href={"/docs/BogdanDumitrescuCVBW.pdf"} className={styles.anchorPad}>
                                    <span className={styles.descTag}>Printer-friendly </span>
                                </a>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            }
        </>
    );
}
