import styles from '../../styles/Home.module.css';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useMediaQuery = (width) => {
    const [targetReached, setTargetReached] = React.useState(false);
  
    const updateTarget = React.useCallback((e) => {
      if (e.matches) {
        setTargetReached(true);
      } else {
        setTargetReached(false);
      }
    }, []);
  
    React.useEffect(() => {
      const media = window.matchMedia(`(max-width: ${width}px)`)
      media.addEventListener('change', e => updateTarget(e))
  
      // Check on mount (callback is not called until a change occurs)
      if (media.matches) {
        setTargetReached(true)
      }
  
      return () => media.removeEventListener('change', e => updateTarget(e))
    }, []);
  
    return targetReached;
  };

const useStyles = makeStyles({
  root: {
    width: 270,
    marginTop: '1vh',
    marginBottom: '1vh',
    marginLeft: '1vw',
    marginRight: '1vw',
    boxShadow: '2px 2px 5px #1f1f1f',
  },
  rootMobile: {
    width: 270,
    maxHeight: 170,
    marginTop: '0.65vh',
    marginBottom: '0.65vh',
    marginLeft: '1vw',
    marginRight: '1vw',
    boxShadow: '2px 2px 5px #1f1f1f',
  },
});

export default function ContactWidget() {
  const classes = useStyles();
  const isBreakpoint = useMediaQuery(1023);

  return (
      <>
        {!isBreakpoint &&
        <>
            <p className={styles.description}>
                I prefer to be contacted through LinkedIn, but you can also email me directly. If you'd like to take a look at my Github, you can do so by clicking on the link below.
            </p>
            <div className={styles.row}>
                <Card className={classes.root}>
                    <a href="mailto:hello@bogdumi.dev">
                        <CardMedia
                            className={styles.cardArea}
                            image="/img/contact/email.png"
                            title="Email"
                        />
                    </a>
                    <CardContent style={{padding: '10px'}}>
                        <div className={styles.tags}>
                            <span className={styles.tag}>Direct Communication</span>
                            <span className={styles.tag}>Urgent Messages</span>
                            <a href="mailto:hello@bogdumi.dev" className={styles.anchorPad}>
                                <span className={styles.descTag}>Email Me</span>
                            </a>
                        </div>
                    </CardContent>
                </Card>
                <Card className={classes.root}>
                    <a href="https://www.linkedin.com/in/bogdumi/" target="_blank" rel="noreferrer">
                        <CardMedia
                            className={styles.cardArea}
                            image="/img/contact/linkedin.png"
                            title="LinkedIn"
                        />
                    </a>
                    <CardContent style={{padding: '10px'}}>
                        <div className={styles.tags}>
                            <span className={styles.tag}>Networking</span>
                            <span className={styles.tag}>Career</span>
                            <span className={styles.tag}>Skills</span>
                            <a href="https://www.linkedin.com/in/bogdumi/" target="_blank" rel="noreferrer" className={styles.anchorPad}>
                                <span className={styles.descTag}>Message me</span>
                            </a>
                        </div>
                    </CardContent>
                </Card>
                <Card className={classes.root}>
                    <a href="https://github.com/bogdumi" target="_blank" rel="noreferrer">
                        <CardMedia
                            className={styles.cardArea}
                            image="/img/contact/github.png"
                            title="GitHub"
                        />
                    </a>
                    <CardContent style={{padding: '10px'}}>
                        <div className={styles.tags}>
                        <span className={styles.tag}>Open Source</span>
                        <span className={styles.tag}>Showcase</span>
                        <span className={styles.tag}>Stats</span>
                            <a href="https://github.com/bogdumi" target="_blank" rel="noreferrer" className={styles.anchorPad}>
                                <span className={styles.descTag}>My Projects</span>
                            </a>
                        </div>
                </CardContent>
            </Card>
          </div>
        </>
        }
        {isBreakpoint &&
            <div className={styles.column}>
                <Card className={classes.rootMobile}>
                    <a href="mailto:hello@bogdumi.dev">
                        <CardMedia
                            className={styles.cardAreaMobile}
                            image="/img/contact/email.png"
                            title="Email"
                        />
                    </a>
                    <CardContent style={{padding: '10px'}}>
                        <div className={styles.tags}>
                            <span className={styles.tag}>Direct Communication</span>
                            <a href="mailto:hello@bogdumi.dev" className={styles.anchorPad}>
                                <span className={styles.descTag}>Email Me</span>
                            </a>
                        </div>
                    </CardContent>
                </Card>
                <Card className={classes.rootMobile}>
                    <a href="https://www.linkedin.com/in/bogdumi/" target="_blank" rel="noreferrer">
                        <CardMedia
                            className={styles.cardAreaMobile}
                            image="/img/contact/linkedin.png"
                            title="LinkedIn"
                        />
                    </a>
                    <CardContent style={{padding: '10px'}}>
                        <div className={styles.tags}>
                            <span className={styles.tag}>Networking</span>
                            <span className={styles.tag}>Career</span>
                            <a href="https://www.linkedin.com/in/bogdumi/" target="_blank" rel="noreferrer" className={styles.anchorPad}>
                                <span className={styles.descTag}>Message me</span>
                            </a>
                        </div>
                    </CardContent>
                </Card>
                <Card className={classes.rootMobile}>
                    <a href="https://github.com/bogdumi" target="_blank" rel="noreferrer">
                        <CardMedia
                            className={styles.cardAreaMobile}
                            image="/img/contact/github.png"
                            title="GitHub"
                        />
                    </a>
                    <CardContent style={{padding: '10px'}}>
                        <div className={styles.tags}>
                            <span className={styles.tag}>Showcase</span>
                            <span className={styles.tag}>Stats</span>
                            <a href="https://github.com/bogdumi" target="_blank" rel="noreferrer" className={styles.anchorPad}>
                                <span className={styles.descTag}>My Projects</span>
                            </a>
                        </div>
                </CardContent>
            </Card>
          </div>
        }
    </>
  )
}
