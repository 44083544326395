import styles from '../styles/Home.module.css'
import React from 'react';
import smoothscroll from 'smoothscroll-polyfill';

export default function Footer() {
  React.useEffect(() => {
    // window is accessible here.
    smoothscroll.polyfill();
  }, []);

  return (
      <footer className={styles.footer}>
        <div className={styles.but} onClick={() => document.getElementById("home").scrollIntoView({ behavior: 'smooth' })}>
          <div className = {styles.borderFadeSmall}>Home</div>
        </div>
        <div className={styles.but} onClick={() => document.getElementById("aboutme").scrollIntoView({ behavior: 'smooth' })}>
          <div className = {styles.borderFadeSmall}>About</div>
        </div>
        <div className={styles.but} onClick={() => document.getElementById("projects").scrollIntoView({ behavior: 'smooth' })}>
          <div className = {styles.borderFadeSmall}>Projects</div>
        </div>
        <div className={styles.but} onClick={() => document.getElementById("contact").scrollIntoView({ behavior: 'smooth' })}>
          <div className = {styles.borderFadeSmall}>Contact</div>
        </div>
        <div className={styles.but} onClick={() => document.getElementById("cv").scrollIntoView({ behavior: 'smooth' })}>
          <div className = {styles.borderFadeSmall}>CV</div>
        </div>
      </footer>
  );
}
