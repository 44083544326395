import React from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import styles from '../styles/Home.module.css'

export default function Landing(props) {
  React.useEffect(() => {
    // window is accessible here.
    smoothscroll.polyfill();
  }, []);

  return (
    <main className={styles.main}>
        <h2 className={styles.subtitle}>
            Nice to meet you
        </h2>
        <h1 className={styles.animTitle}>
            I'm Bogdan Dumitrescu
        </h1>
        <div className={styles.but} onClick={() => document.getElementById("aboutme").scrollIntoView({ behavior: 'smooth' })}>
          <h2 className={styles.borderFade}>
            Get to know me
          </h2>
        </div>
    </main>
  )
}
