import anime from 'animejs/lib/anime.es.js';
import styles from '../styles/Home.module.css'
import React, { useRef, useEffect } from "react";
import smoothscroll from 'smoothscroll-polyfill';

let drawerOpen = false;

const extendDrawer = () => {
    let elements = document.getElementById("drawer");
    anime({
        targets: elements,
        easing: 'easeInOutExpo',
        translateY: -430,
    });
    drawerOpen = true;
}

const retractDrawer = () => {
    let elements = document.getElementById("drawer");
    anime({
        targets: elements,
        easing: 'easeInOutExpo',
        translateY: 0,
    });
    drawerOpen = false;
}

const toggleDrawer = () => {
    if (!drawerOpen) extendDrawer();
    else retractDrawer();
}

function useOutsideAlerter(ref) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target) && drawerOpen) {
                retractDrawer();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export default function Navbar() {
  React.useEffect(() => {
    // window is accessible here.
    smoothscroll.polyfill();
  }, []);

  const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

  return (
    <footer className={styles.footer} id={"drawer"} ref={wrapperRef}>
        <div className={styles.but} onClick={toggleDrawer}>
            <div className = {styles.borderFadeSmall}>Sections</div>
        </div>
        <div className={styles.but} onClick={() => document.getElementById("home").scrollIntoView({ behavior: 'smooth' })}>
            <div className = {styles.borderFadeSmall}>Home</div>
        </div>
        <div className={styles.but} onClick={() => document.getElementById("aboutme").scrollIntoView({ behavior: 'smooth' })}>
            <div className = {styles.borderFadeSmall}>About</div>
        </div>
        <div className={styles.but} onClick={() => document.getElementById("projects").scrollIntoView({ behavior: 'smooth' })}>
            <div className = {styles.borderFadeSmall}>Projects</div>
        </div>
        <div className={styles.but} onClick={() => document.getElementById("contact").scrollIntoView({ behavior: 'smooth' })}>
            <div className = {styles.borderFadeSmall}>Contact</div>
        </div>
        <div className={styles.but} onClick={() => document.getElementById("cv").scrollIntoView({ behavior: 'smooth' })}>
            <div className = {styles.borderFadeSmall}>CV</div>
        </div>
    </footer>
  );
}
