import styles from '../styles/Home.module.css';
import ContactWidget from './aux/contactWidget.js';
import React from 'react';

export default function Cv() {

  return (
    <main className={styles.main}>
        <h1 className={styles.title}>
            Contact Me
        </h1>
        <ContactWidget />
        <div className={styles.but} onClick={() => document.getElementById("cv").scrollIntoView({ behavior: 'smooth' })}>
          <h2 className={styles.borderFade}>
            View my CV
          </h2>
        </div>
    </main>
  )
}
