import styles from '../styles/Home.module.css';
import CvWidget from './aux/cvWidget.js';
import React from 'react';

const useMediaQuery = (width) => {
  const [targetReached, setTargetReached] = React.useState(false);

  const updateTarget = React.useCallback((e) => {
    if (e.matches) {
      setTargetReached(true);
    } else {
      setTargetReached(false);
    }
  }, []);

  React.useEffect(() => {
    const media = window.matchMedia(`(max-width: ${width}px)`)
    media.addEventListener('change', e => updateTarget(e))

    // Check on mount (callback is not called until a change occurs)
    if (media.matches) {
      setTargetReached(true)
    }

    return () => media.removeEventListener('change', e => updateTarget(e))
  }, []);

  return targetReached;
};

export default function Cv() {

  return (
    <main className={styles.main}>
        <h1 className={styles.title}>
            Curriculum Vitae
        </h1>
        <CvWidget />
        <div className={styles.but} onClick={() => document.getElementById("home").scrollIntoView({ behavior: 'smooth' })}>
          <h2 className={styles.borderFade}>
            Go back to the top
          </h2>
        </div>
    </main>
  )
}
